article {
    .breadcrumbs {
        @include leader(1);
        @include trailer(1);
    }
    img {
        width: 100%;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include rhythm(0.5 , 0);
    }
}
