.bar {
    padding: lines-to-px(0.5) lines-to-px(1);
    width: 100%;
    @include leader(3);
    border: 1px solid palette(grey, lines);
    img {
        max-width: 100%;
        height: auto;
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        height: 260px;
    }
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        height: 230px;
        padding: lines-to-px(0.5) lines-to-px(1.5);
        .button {
            @include trailer(0.5);
        }
        h3 {
            max-width: 190px;
            @include rhythm(0);
            @include trailer(0.5);
        }
        .contact {
            float: left;
            h6 {
                @include rhythm(0);
                color: palette(green, base);
                line-height: 1rem;
            }
            h6.small {
                color: palette(blue, base);
            }
            &__left {
                float: left;
                width: 30px;
            }
            &__right {
                float: left;
                margin-right: 10px;
            }
            &__item {
                float: right;
                &--small .navContact__right {
                    width: 30px;
                }
            }
        }
    }
    &__left {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(4);
        }
    }
    &__right {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(last 8);
            img {
                margin: -46px -3px;
                max-height: 260px;
            }
        }
        @media only screen and (max-width: 1300px) {
            img:nth-last-child(-n+1) {
                display: none;
            }
        }

        @media only screen and (max-width: 1024px) {
            img:nth-last-child(-n+2) {
                display: none;
            }
        }

        @media only screen and (max-width: 900px) {
            img:nth-last-child(-n+3) {
                display: none;
            }
        }

        @media only screen and (max-width: 767px) {
            img:nth-last-child(-n+4) {
                display: none;
            }
            img {
                max-height: 230px;
                position: absolute;
                z-index: 0;
                right: 0;
                top: -39px;
            }
        }
    }
    // DIRTY MOBILE STYLES :O
    @media only screen and (max-width: 767px) {
        position: relative;
        h3 {
            position: relative;
            z-index: 1;
        }
        .contact__item {
            margin-top: 10px;
            display: inline-block;
            width: 40%;
            height: auto;
            overflow: hidden;
            h6 {
                margin: 0;
            }
        }
    }
}
