.breadcrumbs {
    @include set-type-size(small);
    @extend %tw-semi-bold;
    padding: 0;
    margin: 0;
    @include trailer(0.5);

    li {
        padding: 0;
        padding-left: lines-to-px(0.5);
        margin: 0;
        &:before {
            content: none;
        }
        &:first-child {
            padding-left: 0;
        }
        display: inline;
    }
    img {
        margin-left: lines-to-px(0.5);
        width: auto;
    }
    a {
        color: palette(white);
    }
    &--dark {
        a {
            color: palette(blue, base);
        }
    }
}
