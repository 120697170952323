.popUp {
    background-color: #fff;
    max-width: 1400px;
    border-radius: 5px;
    margin: 10px 20px;
    position: relative;
    width: 89%;
    @media only screen and (min-width: 1400px) {
        margin-left: auto;
        margin-right: auto;
    }
    &__right {
        padding: 20px;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(last 6);
            padding: 30px;
        }
    }
    &__left {
        height: 300px;
        background-size: cover;
        background-position: center center;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(6);
            height: 550px;
        }
    }
    .js-close-button {
        position: absolute;
        top: -21px;
        outline: 0;
        cursor: pointer;
        z-index: 999;
        background-color: transparent;
        border: 0;
        right: -21px;
        &:hover img {
            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.6);
        }
        img {
            transition: all ease 0.3s;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            border-radius: 90px;
        }
    }
}
