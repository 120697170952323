// Example page
header {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(2);
        @include pre(1);
    }
}

.contentSidebar {
    .sidebar {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(8);
            @include pre(2);
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(3);
            @include pull(9);
        }
        &--right {
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(8);
                @include pre(0);
            }
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                @include span(last 3);
                @include pull(0);
            }
        }
    }
    .content {
        h1 {
            color: palette(blue, base);
        }
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(8);
            @include pre(2);
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(6);
            @include pre(0);
            @include push(3);
        }
    }
}

.page {
    &--404 {
        text-align: left;
        video {
            @include leader(1);
            @include trailer(1);
            max-width: 100%;
            height: auto;
        }
    }
    &__left {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(6);
        }
    }
    &__right {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(last 6);
        }
    }
}
