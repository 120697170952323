// Mobile menu stylezz
.nav {
    &__hamburger {
        position: absolute;
        top: 1px;
        z-index: 999;
        right: -15px;
        width: 90px;
        height: 56px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        background-color: palette(blue);
        cursor: pointer;
        display: block;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            display: none;
        }
        &:before {
            content: 'Menu';
            position: absolute;
            top: 21px;
            left: 15px;
            color: palette(white);
            font-weight: 700;
            font-size: 10px;
            line-height: 17px;
        }
        &.open {
            &:before {
                content: 'Sluit';
            }
        }
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 16px;
            background-color: palette(white);
            border-radius: 9px;
            opacity: 1;
            right: 25px;
            transform: rotate(0deg);
            transition: .2s ease-in-out;
            &:nth-child(1) {
                top: 22px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                width: 20px;
                margin-right: -2px;
                top: 28px;
            }
            &:nth-child(4) {
                top: 34px;
            }
        }
        &.open span:nth-child(1) {
            opacity: 0;
        }
        &.open span:nth-child(2) {
            transform: rotate(45deg);
        }
        &.open span:nth-child(3) {
            transform: rotate(-45deg);
        }
        &.open span:nth-child(4) {
            opacity: 0;
        }
    }
}
