/* ---------------------------------------------------------------------------
    rem

    Calculates rem values for the given font sizes in pixels

    Also provides px based fallback for browsers without rem support (IE8)
*/

//noinspection CssOverwrittenProperties
@mixin pxrem($target, $context: 16) {
    font-size: ($target) + px;
    font-size: ($target / $context) * 1rem;
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

/* ---------------------------------------------------------------------------
    flexEmbed

    Flexible media embeds

    For use with media embeds – such as videos, slideshows, or even images –
    that need to retain a specific aspect ratio but adapt to the width of their
    containing element.

    Based on https://github.com/suitcss/flex-embed/blob/master/flex-embed.css

    Example:
    .item-to-flex{
        @include flexEmbed(1000, 480);
        background-image: url('http://placeimg.com/1000/480/any');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 auto;
        max-height: 480px;
        max-width: 1000px;
    }

    Arguments:
    $width       : The width of the element
    $height      : The height of the element
    $hasItem     : [optional] Add extra output if element contains an iframe, object, embed, etc
    $itemClass   : [optional] Class of element to be positioned inside container
*/
@mixin flexEmbed($width, $height, $hasItem: false, $itemClass: false) {
    // Calc ratio
    $ratio: ($height / $width)*100%;
    display: block;
    overflow: hidden;
    position: relative;

    // This is where the magic happens
    &:before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: $ratio;
    }

    // Set element style (embedded youtube clip for example)
    @if $hasItem {
        @if $itemClass {
            .#{$itemClass},
            iframe,
            embed,
            object {
                bottom: 0;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        } @else {
            iframe,
            embed,
            object {
                bottom: 0;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}
