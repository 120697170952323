// Debug?
$debug: false !default;

////////////////////////////////
//  Breakpoints
////////////////////////////////
$breakpoint-small: 30em;
$breakpoint-medium: 45em;
$breakpoint-large: 60em;
$breakpoint-nav: 72em;
$breakpoint-large-wrapper: 0 80em;


// dpi breakpoints
$breakpoint-hidpi: min-resolution 1.5dppx;

////////////////////////////////
//  Font family
////////////////////////////////
$font-family: 'Titillium Web', arial, sans-serif;

////////////////////////////////
//  Typography
////////////////////////////////
$ts_font-sizes: (
    base: (
        font-size: 13px,
        line-height: 2.1,
        ratio: 1.13,
    ),
    large: (
        font-size: 16px,
        line-height: 2.4,
        ratio: 1.17,
        breakpoint: $breakpoint-large
    ),
);

$ts_font-scales: (
    x-small: (
        -2
    ),
    small: (
        -1
    ),
    base: (
        0
    ),
    medium: (
        1
    ),
    large: (
        2
    ),
    x-large: (
        3
    ),
    xx-large: (
        6
    ),
    xxx-large: (
        7
    )
);
