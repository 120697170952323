.praktijken {
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        .about {
            @include span(6);
        }
        .employees {
            @include span(last 6);
            h3 a {
                float: right;
                margin-right: 20px;
            }
            .employee {
                @include span(2 of 6);
                &:nth-of-type(3n+3) {
                    @include span(last 2 of 6);
                }
            }
        }
    }
}
