img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    &.rounded {
        border-radius: 200px;
        overflow: hidden;
    }
}

figure {
    margin: 0;
    position: relative;
    clear: both;
    width: 100%;
}
