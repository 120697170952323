.animalCards {
    border-radius: 1px;
    width: 100%;
    &__item {
        background: palette(white);
        &--icon {
            @include rhythm(0.5,0.5);
            text-align: center;
            img {
                @media only screen and (max-width: 960px) {
                    float: left;
                    margin-left: 20px !important;
                }
                @include susy-breakpoint($breakpoint-medium, $susy-large) {
                    float: none;
                }
            }
        }
    }
}

.animalCards {
    border-radius: 1px;
    width: 100%;
    height: auto;
    overflow: hidden;
    &__item {
        background: palette(white);
        display: block;
        &--dark {
            background: palette(blue, base);
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(6);
                &:nth-child(2n+2) {
                    @include span(last 6);
                }
            }
        }
        &--image {
            background: transparent;
            @include trailer(1);
            transition: all ease 0.2s;
            img {
                box-shadow: 0 5px 10px 0 rgba(0, 28, 105, 0.1);
                border-radius: 3px;
                transition: all ease 0.2s;
            }
            @include span(2);
            &:nth-child(2n+2) {
                @include span(last 2);
            }
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(3 of 6);
                &:nth-child(2n+2) {
                    @include span(last 3 of 6);
                }
            }
            &:hover {
                img {
                    box-shadow: 0 5px 10px 0 rgba(0, 28, 105, 0.3);
                }
                a:after {
                    right: -25px;
                }
            }
        }
        &--icon {
            @include rhythm(0.5,0.5);
            text-align: center;
            overflow: hidden;
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                height: 75px;
                height: inherit;
            }
            &:hover {
                box-shadow: 0 5px 10px 0 rgba(0, 28, 105, 0.1);
            }
            img {
                //float: left;
                height: 40px;
                display: block;
                @include susy-breakpoint($breakpoint-medium, $susy-large) {
                    margin: 4px auto 0 !important;
                }
            }
            span {
                float: right;
                margin-right: 40px;
                margin-top: 10px;
                @include susy-breakpoint($breakpoint-medium, $susy-large) {
                    width: 100%;
                }
            }
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                span,
                img {
                    float: initial;
                    margin: 0;
                }
            }
        }
    }
}

.specialism {
    background-color: palette(white);
    border: 1px solid palette(grey, lines);
    padding: lines-to-px(1);
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        padding: lines-to-px(1);
    }
    h4 {
        @include rhythm(0);
        @include trailer(0.5);
    }
    .button {
        width: 100%;
        text-align: left;
        &:after {
            right: 0;
        }
        &:hover:after {
            right: -10px;
        }
    }
    &__item {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include trailer(0.5);
            @include span(3 of 6);
            &:nth-child(2n+3) {
                @include span(last 3 of 6);
            }
        }
    }
}
