.button {
    @include set-type-size(base);
    @extend %tw-semi-bold;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-radius: 2px;
    color: palette(white);
    text-decoration: none;
    border: 0;
    outline: none;
    padding: lines-to-px(0.2) lines-to-px(1.4);
    &__primary {
        background-color: palette(green);
        padding: lines-to-px(0.2) lines-to-px(0.6);
        img {
            padding-right: 8px;
        }
        &:hover {
            color: #fff;
            background-color: palette(green, dark);
        }
    }
    &__tags {
        border-radius: 100px;
        padding: lines-to-px(0.2) lines-to-px(1.4);
        margin-bottom: 5px;
        &--light {
            color: palette(blue);
            background-color: palette(babyBlue, base);
            &:hover {
                color: palette(blue);
                background-color: palette(babyBlue, dark);
            }
        }
        &--dark {
            background-color: palette(blue);
            &:hover {
                color: palette(white);
                background-color: palette(blue, dark);
            }
        }
    }
    &__naked {
        padding: 0;
        position: relative;
        &--primary {
            color: palette(green);
            &:after {
                content: '';
                background-image: url('/assets/images/pijltje-rechts-groen.svg');
                background-repeat: no-repeat;
                background-position: center;
                height: 12px;
                width: 12px;
                position: absolute;
                right: -20px;
                transition: ease 0.2s;
                top: 0.7rem;
                @include susy-breakpoint($breakpoint-medium, $susy-large) {
                    top: 0.9rem;
                }
            }
            &:hover:after {
                right: -25px;
            }
        }
        &--secondary {
            color: palette(blue);
            &:after {
                content: '';
                background-image: url('/assets/images/pijltje-rechts-blauw.svg');
                background-repeat: no-repeat;
                background-position: center;
                height: 12px;
                width: 12px;
                position: absolute;
                right: -20px;
                transition: ease 0.2s;
                top: 0.7rem;
                @include susy-breakpoint($breakpoint-medium, $susy-large) {
                    right: 30px;
                    top: 0.9rem;
                }
            }
            &:hover:after {
                right: 15px;
            }
        }
        &--white {
            color: palette(white);
            &:after {
                content: '';
                background-image: url('/assets/images/pijltje-rechts-wit.svg');
                background-repeat: no-repeat;
                background-position: center;
                height: 12px;
                width: 12px;
                position: absolute;
                right: -20px;
                transition: ease 0.2s;
                top: 0.7rem;
                @include susy-breakpoint($breakpoint-medium, $susy-large) {
                    top: 0.9rem;
                }
            }
            &:hover {
                color: palette(white);
                :after {
                    right: -25px;
                }
            }
        }
        &--light {
            color: palette(blue);
            background-color: palette(babyBlue, base);
            &:hover {
                color: palette(blue);
                background-color: palette(babyBlue, dark);
            }
            &.active {
                color: palette(white);
                background-color: palette(blue);
            }
        }
    }
    &--right {
        margin-top: 20px;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            margin: 20px;
            float: right;
        }
    }
}
