////////////////////////////////
//  Type weights
////////////////////////////////
%tw-extrabold {
    font-weight: 900;
}

%tw-bold {
    font-weight: 700;
}

%tw-semi-bold {
    font-weight: 600;
}

%tw-regular {
    font-weight: 400;
}

%tw-light {
    font-weight: 300;
}

%tw-ultralight {
    font-weight: 100;
}

body {
    padding: 0;
    margin: 0;
}

////////////////////////////////
//  Default styles - edit if needed
////////////////////////////////
p,
ol,
ul,
blockquote {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @include set-type-size(base);
    @include rhythm(1);
}
