input {
    &:focus {
        outline: none;
    }
}

.input--text,
.input--text:valid {
    @include set-type-size(small);
    @include lines-to-px(1);
    display: block;
    margin: 0;
    border-radius: 2px;
}

.input--error,
.input--text:invalid {
    border: 2px solid #be3838;
}

input,
textarea {
    outline: none;
    padding: lines-to-px(1) lines-to-px(0.5);
    border: 2px solid palette(grey, lines);
    transition: .2s all;
    color: palette(grey);
    &:focus {
        border-color: palette(blue, dark);
    }
}

textarea {
    display: block;
}

.button__input--checkbox {
    padding: 0;
    label {
        float: left;
        position: relative;
        overflow: hidden;
        span {
            text-align: center;
            display: block;
            @extend .button;
            @extend .button__tags;
            @extend .button__tags--light;
            cursor: pointer;
        }
        input {
            position: absolute;
            top: -20px;
            &:checked + span {
                color: #fff;
                @extend .button__tags--dark;
            }
        }
    }
}
