// Navigation
nav {
    ul {
        padding: 0;
        margin: 0;
        display: none;

        &.active {
            display: block;
            ul {
                display: block;
            }
        }
    }
    li {
        padding: 0;
        margin: 0;
        display: block;
        position: static;
        &:before {
            content: '';
        }
    }
    // For fixed menu wrapper
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        height: 160px;
    }
}

// Mobile menu
.nav {
    .baseNav {
        height: 56px;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            height: 80px;
        }
        &:before {
            height: 56px;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                height: 80px;
            }
        }
    }
    .js-site-nav.active {
        overflow: hidden;
        height: 0;
        min-height: 0;
        background-color: palette(babyBlue, light);
        text-align: left;
        @media only screen and (min-width: 1035px) {
            padding-left: lines-to-px(2);
            padding-right: lines-to-px(2);
        }
        a {
            color: palette(blue);
            @include set-type-size(base);
            text-decoration: none;
        }
        li {
            line-height: 2.5;
            img {
                display: none;
            }
            @include rhythm(0, 0.5);
            text-align: center;
            position: relative;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                &.has-children.level1 {
                    position: initial;
                }
            }
            &.has-children {
                &:after {
                    content: '';
                    position: absolute;
                    top: 18px;
                    right: 10%;
                    width: 30px;
                    height: 30px;
                    background-image: url('/assets/images/plus-icon-blauw.svg');
                    background-repeat: no-repeat;
                    z-index: 9999;
                }
                ul {
                    display: none;
                    @include rhythm(0);
                    a {
                        @include set-type-size(base);
                        @include susy-breakpoint($breakpoint-large, $susy-large) {
                            display: block;
                        }
                        @extend %tw-regular;
                    }
                    li {
                        border: 0;
                        padding: 0;
                        &:after {
                            content: none;
                        }
                    }
                }
                &.active {
                    ul {
                        display: inline;
                    }
                    &:after {
                        top: 25px;
                        background-image: url('/assets/images/min-icon-blauw.svg');
                    }
                }
            }
            border-bottom: 2px solid rgba(249, 252, 253, 0.12);
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            background-color: inherit;
            height: inherit;
            li {
                text-align: left;
            }
        }
    }
}

.baseNav {
    position: relative;
    &:before {
        content: '';
        left: -15px;
        right: -15px;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            left: -100%;
            right: -100%;
        }
        position: absolute;
        z-index: -1;
        height: 80px;
        bottom: 0;
        border-top: 1px solid palette(babyBlue, dark);
        background-color: palette(babyBlue, light);
    }
}

.nav__item {
    @include set-type-size(small);
    width: 100%;
    a {
        color: palette(blue);
        @include set-type-size(small);
        @extend %tw-semi-bold;
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        display: inline-block;
        width: auto;
        padding-top: 20px;
        padding-bottom: 22px;
        padding-right: 30px;
        a {
            color: palette(blue);
        }
    }
}

// Desktop stylezz
@include susy-breakpoint($breakpoint-large, $susy-large) {
    // Submenu -> show
    .nav .level1 ul {
        display: inline-block !important;
    }

    // Submenu -> show
    .nav .level1:hover ul {
        display: inline !important;
        visibility: visible !important;
    }

    .nav .js-site-nav.active {
        overflow: inherit;
    }
    // List level 2 position
    .nav li.level2,
    .nav li.level3 {
        position: relative;
        z-index: 9999;
        display: block;
    }
    .nav li.has-children {
        &:after {
            content: none !important;
        }
    }
    // Create cols for li level2
    .nav li.level2 {
        width: 200px;
        float: left;
        display: block;
        padding-top: 20px;
        > a {
            font-weight: 600 !important;
        }
    }

    // change font li level3
    .nav li.level3 {
        a {
            font-weight: 300;
        }
    }

    // dropdown image
    .nav li.level1 {
        img {
            padding-left: 8px;
            display: inline !important;
            transition: all ease 0.8s;
        }
        &:hover img {
            -moz-transform: scaleY(-1);
            -o-transform: scaleY(-1);
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
    }

    // Dropdown effect
    .nav .level1 ul {
        position: absolute;
        z-index: 99999;
        li {
            padding: 5px 0;
        }
    }
    .nav .level1 > ul {
        padding-top: 40px !important;
        padding-left: 30px;
        visibility: hidden;
        margin-left: -30px;
        li {
            opacity: 0;
        }
        &:before {
            content: ' ';
            border-right: 1px solid palette(grey, lines);
            border-left: 1px solid palette(grey, lines);
            background-color: palette(babyBlue, light);
            width: 100%;
            max-height: 0;
            height: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 22px;
            bottom: 0;
        }
    }
    .nav .level1:hover > ul {
        display: block !important;
        @for $i from 1 to 20 {
            li:nth-child(#{$i}) {
                transition-delay: $i * 0.07s + 0.25s;
            }
        }
        li {
            opacity: 1;
            transition: all ease 0.4s;
            // transition-delay: 0.45s;
        }
        &:before {
            max-height: 330px;
            height: 330px;
            transition: height ease 0.5s;
        }
    }
    .nav .level1.simple {
        padding-bottom: 20px;
        &:hover {
            > a {
                color: #7ebe38;
            }
        }
        > ul:before {
            height: 100%;
            max-width: 340px;
        }
        .level2 {
            width: 100%;
            a {
                font-weight: 300 !important;
            }
        }
    }
}

// Voor het scrollen fixed desktop
.js-base-nav-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    z-index: 999;
    max-width: 1238px;
    margin: 0 auto;
}

.logo-placeholder {
    display: inline;
    img {
        margin-right: 20px;
        max-width: 100px;
    }
}
