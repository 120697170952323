.stories {
    h3 > a {
        float: right;
        margin-right: 20px;
    }
    &__item {
        border: 1px solid palette(grey, lines);
        padding: lines-to-px(1);
    }
    &__image {
        @include span(1);
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(4);
        }
    }
    &__title {
        h6 {
            @include rhythm(0);
        }
        @include span(last 3);
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(last 8);
        }
    }
}
