.sidebar {
    @include leader(3);
    &__inner {
        padding: lines-to-px(1);
        position: relative;
        border: 1px solid palette(grey, lines);
        h6 {
            @include rhythm(0);
        }
        &--global {
            .small {
                @include set-type-size(x-small);
                color: palette(grey, light);
                padding-left: 4px;
            }
            .button__primary {
                z-index: 99;
                position: relative;
                margin-top: 250px;
            }
        }
        &--local {
            .sidebar__logo {
                padding: 20px;
                text-align: center;
                z-index: 99;
                position: relative;
                margin-top: 200px;
                margin-bottom: 20px;
                background-color: palette(white);
                box-shadow: 0 5px 30px 0 rgba(36, 44, 82, 0.1);
            }
        }
        h4 {
            z-index: 99;
            max-width: 180px;
            position: relative;
            @include rhythm(0);
        }
    }
    &__tom {
        img {
            position: absolute;
            top: -50px;
            z-index: 1;
            right: 0;
            max-height: 260px;
        }
    }
    &__news {
        border: 1px solid palette(grey, lines);
        text-align: center;
        h6.small {
            @include set-type-size(small);
        }
        .sidebar__text {
            @include rhythm(0.5);
            h6 {
                @include rhythm(0);
            }
            p {
                @include rhythm(0);
                @include set-type-size(small);
            }
        }
    }
    &__menu {
        .button {
            width: 100%;
            text-align: left;
            &:after {
                right: 0;
            }
            &:hover:after {
                right: -10px;
            }
        }
    }
    &__item a {
        text-align: left;
    }
}
