///////////////////////////////////////////////////////////////////////
// At a minimum every palette defines a base colour,
// and then optionally adds tones use the following naming pattern:
//
// - x-dark
// - dark *
// - mid-dark
// - base (default)
// - mid-light
// - light *
// - x-light
//
//
// *If we have fewer than seven tones, we’ll define light and dark first to allow
// for adding intermediate tones at a later date.
//
// Use the palette function
//
// @link http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/
//
//
$color-text-default: #5b6277;

$palettes: (
    grey: (
        dark: darken(rgb(128, 128, 128), 10%),
        base: #5b6277,
        light: lighten(rgb(128, 128, 128), 10%),
        lines: #dfe0e5,
    ),
    white: (
        base: #fff
    ),
    green: (
        dark: #73af32,
        base: #7ebe38,
    ),
    blue: (
        dark: #151e4d,
        base: #1a245c,
    ),
    babyBlue: (
        dark: #dee0e9,
        base: #e5e8f0,
        light: #f9faff,
    )
);

.green {
    color: #7ebe38;
}

///////////////////////////////////
// Access the colors in the $palettes map
// Example: color: palette(grey); color: palette(grey, light);
//

@function palette($palette, $tone: 'base') {
    @return map-get(map-get($palettes, $palette), $tone);
}
