::-moz-selection {
    text-shadow: none;
    color: palette(white);
    background-color: palette(green);
}

::selection {
    color: palette(white);
    background-color: palette(green);
    text-shadow: none;
}
