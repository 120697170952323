.contact {
    .breadcrumbs {
        @include leader(1);
    }
    h1 {
        color: palette(blue);
    }
    article {
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include pre(3);
            @include span(6);
        }
    }
    .maps {
        height: 400px;
        @include trailer(1);
    }
    .contactForm {
        @include trailer(1);
    }
    .tab {
        &__content {
            display: none;
            &.active {
                display: block;
            }
            button {
                @extend .button;
                @extend .button__primary;
            }
        }
    }
}
