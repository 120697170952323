.locatieZoeker {
    #app {
        height: auto;
        overflow: hidden;
    }
    h1 {
        @include rhythm(0);
        @include trailer(0.5);
        color: palette(blue);
    }
    .sidebar {
        height: 550px;
        margin-top: 0;
        overflow: hidden;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(inside no-gutters 4);
            overflow: hidden;
            border-right: 1px solid palette(grey, lines);
            height: 900px;
        }
    }

    main input {
        width: 100%;
    }

    .pad2 {
        padding: 20px;
    }

    .quiet {
        color: #888;
    }

    .map {
        height: 400px;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(last inside no-gutters 8);
            overflow: hidden;
            border-right: 1px solid palette(grey, lines);
            height: 900px;
        }
    }

    .heading {
        background: #fff;
        border-bottom: 1px solid palette(grey, lines);
        height: auto;
        padding: 10px;
    }

    #listingDiv {
        overflow: auto;
        width: 100%;
    }

    .listings {
        overflow: auto;
        height: 270px;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            padding-bottom: 380px;
            height: 100%;
        }
        .left {
            float: right;
        }
        .right {
            line-height: 1.5rem;
        }
    }

    .sidebar fieldset {
        padding: 0;
        margin: 0;
        border: 0;
        margin-top: 10px;
        position: relative;
        z-index: 1;
    }

    .listings .item {
        display: block;
        border-bottom: 1px solid palette(grey, lines);
        padding: 10px;
        text-decoration: none;
    }

    .listings .item:last-child {
        border-bottom: none;
    }

    .listings .item .title {
        display: block;
        color: palette(blue);
        font-weight: 700;
    }

    .listings .item .title small {
        font-weight: 400;
    }

    .listings .item.active .title,
    .listings .item .title:hover {
        color: palette(green, base);
    }

    .listings .item.active {
        background-color: #f8f8f8;
    }

    #storeinfo {
        padding: 2px 10px;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            padding: 10px 10px 20px;
        }
        display: none;
        background-color: palette(green);
        color: palette(white);
        h4 {
            color: palette(white);
            @include rhythm(0);
        }
        a {
            color: palette(white);
        }
    }

    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        border-left: 0;
        background: rgba(0, 0, 0, 0.1);
    }

    ::-webkit-scrollbar-track {
        background: none;
    }

    ::-webkit-scrollbar-thumb {
        background: #00853e;
        border-radius: 0;
    }

    .clearfix {
        display: block;
    }

    .clearfix:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }

    .leaflet-container .leaflet-marker-icon {
        cursor: pointer;
    }

    #closeButton {
        float: right;
    }

    #closeButton:hover,
    :focus {
        color: palette(green);
        cursor: pointer;
    }

    #searchIcon:hover,
    :focus {
        color: palette(green);
        cursor: pointer;
    }

    #infoClose {
        float: right;
    }

    #searchIcon {
        float: right;
        font-size: 26px;
        margin-top: 1px;
    }

    @media screen and (max-width: 900px) {
        #searchIcon {
            font-size: 16px;
        }
    }
}
