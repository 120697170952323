.pagination {
    text-align: center;
    @include rhythm(1);
    @include leader(1);
    .current,
    a {
        margin-left: 8px;
        margin-right: 8px;
    }
}
