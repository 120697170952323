ul,
ol {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    @include susy-media($breakpoint-large) {
        padding-left: 0;
    }
}

ul li {
    position: relative;
    padding-left: 2em;
    &:before {
        font-size: 32px;
        content: '•';
        color: palette(green);
        display: block;
        position: absolute;
        top: -4px;
        left: 0;
    }
}

ol {
    counter-reset: list;
    > li {
        position: relative;
        padding-left: 2em;
        &:before {
            @extend %tw-bold;
            counter-increment: list;
            content: counter(list) '.';
            color: palette(green);
            display: inline-block;
            position: absolute;
            left: 0;
        }

        // This selected every adjacent <li>
        // from the 10th and overrides the
        // content property of each
        &:nth-child(10n) ~ li:before,
        &:nth-child(10n):before {
            content: counter(list);
        }
        p {
            margin: 0;
        }
    }
}

ul ol,
ol ul {
    padding-top: 0;
}

ul.benefits__list {
    li:before {
        content: '';
        background-image: url('/assets/images/check-groen.svg');
        top: 0.9rem;
        height: 10px;
        width: 14px;
    }
}
