/* ==========================================================================
    Base file, all includes, defines & custom mixins should be added here
    ========================================================================= */
// Framework includes
@import "susy";
@import 'modular-scale';
// Vendor includes
@import "vendor/normalize";
@import "vendor/L.VisualClick";
@import "vendor/leaflet";
@import "vendor/animate";
@import "vendor/leaflet-search.src";
@import "vendor/slick";
// Utilities
@import "utilities/mixins";
@import "utilities/variables";
@import "utilities/palettes";
@import "utilities/grid";
// Typography
@import "typesize";
@import "utilities/typography";
@import "utilities/flexit";
@import "utilities/matrix";
// Atoms
@import "atoms/html";
@import "atoms/body";
@import "atoms/buttons";
@import "atoms/headings";
@import "atoms/text-selection";
@import "atoms/links";
@import "atoms/lists";
@import "atoms/img";
@import "atoms/hr";
@import "atoms/inputs";
@import "atoms/labels";
@import "atoms/creditsNiice";
// Molecules
@import "molecules/nav";
@import "molecules/nav-sites";
@import "molecules/nav-contact";
@import "molecules/nav-topNav";
@import "molecules/cookienotice";
@import "molecules/nav-hamburger";
@import "molecules/bar";
@import "molecules/heroCard";
@import "molecules/share";
@import "molecules/newsCard";
@import "molecules/pop-up";
@import "molecules/animalCards";
@import "molecules/employees";
@import "molecules/storiesSpotlight";
@import "molecules/pagination";
@import "molecules/breadcrumbs";
// Organisms
@import "organisms/footer";
@import "organisms/header";
@import "organisms/article";
@import "organisms/form";
@import "organisms/sidebar";
@import "organisms/hero";
// Templates
@import "templates/page";
@import "templates/nieuws";
@import "templates/search";
@import "templates/locatie-zoeker";
@import "templates/verhalen";
@import "templates/contact";
@import "templates/home";
@import "templates/praktijken";
@import "templates/diersoorten";
