a:hover {
    text-decoration: none;
    color: palette(green, dark);
}

a:visited {
    // This space intentionally left blank
}

a:active {
    // This space intentionally left blank
}

a:focus {
    // This space intentionally left blank
}

a,
a[href^=tel] {
    color: palette(green);
    text-decoration: none;
    transition: all ease .15s;
}
