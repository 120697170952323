.zoeken {
    h1 {
        color: palette(blue);
    }
    input[type="submit"] {
        @include leader(1);
    }
    article {
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(6);
            @include pre(3);
        }
    }
    &.resultaten {
        .sidebar {
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                @include span(3);
            }
        }
        article {
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                @include span(6);
                @include pre(0);
            }
        }
    }
}
