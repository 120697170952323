.verhalen-overzicht {
    .stories {
        &__item {
            @include trailer(2);
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(6);
                &:nth-of-type(2n+2) {
                    @include span(last 6);
                }
            }
        }
    }
}
