// Topnav with Oosthof Logo
.topNav {
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        @include leader(0.2);
        &__logo {
            @include span(2);
            img.inactive {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                transition: all ease 0.5s;
                &:hover {
                    -webkit-filter: grayscale(0);
                    filter: grayscale(0);
                }
            }
        }
        &__description {
            @include span(last 10);
        }
    }
    &__mobileDesc {
        h6 {
            padding: 0;
            margin: 0;
            text-align: center;
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        &--inactive {
            margin-top: 2px;
            h6 {
                margin-top: 11px;
                margin-bottom: 5px;
            }
            img {
                height: 20px;
                margin-top: 8px;
                margin-bottom: 8px;
                vertical-align: top;
            }
        }
    }
}

.fixed {
    position: fixed;
}

.pin-t {
    top: 0;
    left: 0;
    padding-left: 15px;
    right: 0;
    margin: 0 auto;
}

.transition-up {
    margin-top: -58px;
    opacity: 0;
    transition: .3s all;
}

.transition-down {
    margin-top: 0;
    opacity: 1;
    transition: .3s all;
}

.topbarFixed {
    z-index: 9999;
    width: 100%;
    max-width: 77.3124999986em;
    &.fixed {
        .sites {
            background-color: #fff;
            &:before {
                left: -100%;
                right: -100%;
                content: '';
                position: absolute;
                z-index: -1;
                height: 81px;
                top: -1px;
                background-color: #fff;
            }
        }
    }
}
