.employee {
    text-align: center;
    &__image {
        box-shadow: 0 5px 10px 0 rgba(0, 28, 105, 0.1);
        border-radius: 200px;
        margin: 0 auto;
        @include trailer(0.5);
        max-width: 170px;
    }
    &__text {
        min-height: 100px;
    }
    h6 {
        @include rhythm(0);
    }
    &--expanded {
        margin-bottom: 20px;
        a {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            @include susy-breakpoint($breakpoint-small, $susy-large) {
                flex-direction: row;
                align-items: center;
                gap: 40px;
            }
        }
        &__image {
            box-shadow: 0 5px 10px 0 rgba(0, 28, 105, 0.1);
            border-radius: 200px;
            width: 120px;
            height: 120px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__text {
            width: 100%;
            flex-grow: 1;
            h4 {
                margin: 0;
                line-height: 32px;
            }
            h6 {
                margin: 0;
                line-height: 27px;
            }
            p {
                margin: 8px 0 0 0;
                color: #5b6277;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}