body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.date {
    @include set-type-size(small);
    color: palette(blue);
    padding-right: 10px;
}

.drift-open-chat {
    cursor: pointer;
}

.relative {
    position: relative;
}
