.hero {
    position: relative;
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        height: 650px;
    }
    video {
        width: 100%;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            min-width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &__bg {
        height: 350px;
        background-size: cover;
        background-repeat: none;
        background-position: center;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            height: 650px;
            overflow: hidden;
            position: relative;
        }
    }
    &__text {
        margin-left: -15px;
        margin-right: -15px;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            height: 100px;
            z-index: 10;
            max-width: 1237px;
            position: absolute;
            top: calc(50% - 34px);
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
        }
        @include susy-breakpoint($breakpoint-large-wrapper, $susy-large) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    &__overlay {
        z-index: 9;
        @include leader(-3);
        padding-bottom: lines-to-px(3);
        background: linear-gradient(0deg, #64b044 80%, rgba(100, 176, 68, 0) 100%);
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include leader(0);
            position: absolute;
            background: linear-gradient(90deg, #7ebe38 0%, rgba(100, 176, 68, 0) 70%);
            height: 650px;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }
    }
    h1 {
        @include rhythm(0);
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            max-width: 630px;
        }
    }
    .subtitel {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            max-width: 630px;
        }
        @include rhythm(0);
        @extend %tw-regular;
        color: palette(white);
    }
    &__animals {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 10;
        max-width: 1237px;
        @include susy-breakpoint($breakpoint-large-wrapper, $susy-large) {
            padding-left: 15px;
            padding-right: 15px;
        }
        .animalCards {
            float: right;
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                // @include span(last 3);
                width: 240px;
                margin-right: 0;
                margin-top: 20px;
            }
        }
    }
}
