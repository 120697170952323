.flexit {
    display: flex;
    height: 100%;
    position: relative;
    &--desktop {
        display: block;
        height: auto;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            display: flex;
            height: 100%;
        }
    }
    &__compatibility {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        &--desktop {
            position: static;
            left: auto;
            right: auto;
            height: auto;
            width: auto;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
        &--mobile {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                position: static;
                left: auto;
                right: auto;
                height: auto;
                width: auto;
            }
        }
    }
    &__helper {
        display: table;
        height: 100%;
        width: 100%;
        &--desktop {
            display: block;
            height: auto;
            width: auto;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                display: table;
                height: 100%;
                width: 100%;
            }
        }
        &--mobile {
            display: table;
            height: 100%;
            width: 100%;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                display: block;
                height: auto;
                width: auto;
            }
        }
    }
    &__holder {
        display: table-cell;
        vertical-align: middle;
        &--desktop {
            display: block;
            vertical-align: inherit;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                display: table-cell;
                vertical-align: middle;
            }
        }
        &--mobile {
            display: table-cell;
            vertical-align: middle;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                display: block;
                vertical-align: inherit;
            }
        }
    }
}
