.share {
    border-top: 1px solid palette(grey, lines);
    @include leader(1);
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        float: left;
        width: 100%;
        text-align: right;
        padding-right: 40px;
    }
    img {
        vertical-align: middle;
        max-width: 48px;
    }
    a {
        margin-left: lines-to-px(0.5);
        margin-right: lines-to-px(0.5);
    }
    &__bar {
        text-align: left;
    }
    &__item {
        display: inline-block;
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        h6 {
            display: inline-block;
        }
    }
}
