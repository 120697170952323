h1 {
    @include set-type-size(xxx-large);
    @include rhythm(1);
    @extend %tw-bold;
    color: palette(white);
}

h2 {
    @include set-type-size(xx-large);
    @include rhythm(1);
    @extend %tw-bold;
    color: palette(blue);
}

h3 {
    @include set-type-size(x-large);
    @include rhythm(1);
    @extend %tw-bold;
    color: palette(blue);
    &.green {
        color: palette(green);
    }
}

h4 {
    @include set-type-size(large);
    @include rhythm(1);
    @extend %tw-bold;
    color: palette(blue);
}

h5 {
    @include set-type-size(medium);
    @include rhythm(1);
    @extend %tw-semi-bold;
    color: palette(blue);
}

h6 {
    @include set-type-size(base);
    @include rhythm(1);
    @extend %tw-semi-bold;
    color: palette(blue);
    &.small {
        @include set-type-size(x-small);
        @include rhythm(0.5);
    }
    &.green {
        color: palette(green, base);
    }
}
