.sitesBorderTop {
    border-top: 1px solid palette(grey, lines);
}

.sites {
    height: 81px;
    @media only screen and (max-width: 767px) {
        height: 57px;
    }
    img {
        max-width: 70%;
    }
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        height: initial;
    }
    &__site {
        display: none;
        height: 56px;
        margin: 0;
        vertical-align: middle;
        &.active {
            display: table-cell;
        }
        &.visible {
            display: table-cell;
        }
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            height: 80px;
            border-right: 1px solid transparent;
            background-color: palette(babyBlue, light);
            background-color: #fff;
            transition: background 0.1s;
            border-left: 1px solid palette(grey, lines);
            border-top: 0;
            padding: lines-to-px(0.5) lines-to-px(0.5);
            img {
                max-width: 90px;
            }
        }
        @include susy-breakpoint($breakpoint-nav, $susy-large) {
            padding: lines-to-px(0.66) lines-to-px(0.5);
            img {
                max-width: inherit;
            }
        }
        a {
            display: block;
        }
        img {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            transition: all 0.2s;
        }
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            &:last-child {
                border-right: 1px solid palette(grey, lines);
            }
        }

        &:hover,
        &.active {
            border-top: 0;
            border-right: 0;
            position: relative;
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                border-left: 1px solid palette(babyBlue, dark);
                border-right: 1px solid transparent;
                background-color: palette(babyBlue, light);
            }
            img {
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                &:before {
                    content: '';
                    position: absolute;
                    right: -3px;
                    bottom: -1px;
                    top: 0;
                    width: 1px;
                    background-color: palette(white);
                    z-index: 9;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -2px;
                    height: 2px;
                    background-color: palette(babyBlue, light);
                    z-index: 9;
                }
                &:last-of-type {
                    border-right: 1px solid palette(babyBlue, dark);
                }
            }
            &:before {
                content: none;
            }
        }
    }
    .button--right--abs {
        top: 8px;
        position: absolute;
        right: 15px;
        height: 49px;
        @media only screen and (max-width: 767px) {
            height: 41px;
        }
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            top: 15px;
            height: initial;
        }
    }
    &__quote {
        display: none;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            display: table-cell;
            padding: 21px;
            color: palette(green);
            font-weight: 500;
        }
    }
}

.site {
    position: relative;
}
