$cookie-bg: #fff;
$cookie-txt: #5b6277;
$cookie-button-bg: #7ebe38;
$cookie-button-text: #fff;
$cookie-button-decline-bg: #fff;
$cookie-button-decline-text: #5b6277;

.cookienotice {
    width: 100%;
    left: 0;
    right: 0;
    background-color: $cookie-bg;
    position: fixed;
    max-width: 1237px;
    margin: 0 auto;
    padding: 40px;
    @media only screen and (max-width: 767px) {
        padding: 10px 20px;
    }
    text-align: left;
    color: $cookie-txt;
    border: 2px solid #7ebe38;
    z-index: 9999999;
    &--top {
        top: 0;
    }
    &--bottom {
        bottom: 0;
    }
    strong {
        color: $cookie-txt;
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 10px;
    }
    p a {
        color: $cookie-button-bg;
    }
    p,
    .cookienotice__button {
        font-size: 13px;
        margin: 0;
        color: $cookie-txt;
        line-height: 1.5;
        @media only screen and (max-width: 767px) {
            font-size: 11px;
        }
    }
    .cookienotice__button {
        cursor: pointer;
        text-decoration: none;
        margin-top: 20px;
        &--decline {
            color: lighten($cookie-txt, 20%);
        }
    }
    .cookienotice__button--accept {
        background-color: $cookie-button-bg;
        color: $cookie-button-text;
        font-weight: 700;
        display: inline-block;
        padding: 10px 30px;
    }
    .cookienotice__button--decline {
        background-color: $cookie-button-decline-bg;
        color: $cookie-button-decline-text;
        font-weight: 700;
        display: inline-block;
        padding: 10px 30px 10px 0;
    }
    &.hide {
        display: none;
    }
}
