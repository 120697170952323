.home {
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        .about {
            @include span(6);
        }

        .news {
            @include span(last 6);
        }

        .benefits {
            @include span(6);
        }

        .stories {
            @include span(last 6);
        }
    }
}
