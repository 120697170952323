/* =============================================
   Grid setup. Also see: http://susy.oddbird.net/demos/grid-types/
   ============================================= */
$susy-debug: (
    debug:(
        image: show-columns,
        color:rgba(0, 128, 0, .2),
        output: background
    )
);
$susy: (
    columns: 4,
    gutters: 1/4,
    math: fluid,
    output: float,
    box-sizing: border-box,
);
$susy-large: (
    columns: 12,
    gutters: 0.7833333333,
    math: fluid,
    output: float,
    column-width: (60px / 16px)  * 1em,
    box-sizing: border-box,
);

@if ($debug) {
    $susy: map-merge($susy, $susy-debug) !global;
    $susy-large: map-merge($susy-large, $susy-debug) !global;
}

// Grid container setup
.wrapper {
    // make it the container of the grid
    @include container;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include container();
    }
    @include susy-breakpoint($breakpoint-large-wrapper, $susy-large) {
        padding-left: 15px;
        padding-right: 15px;
    }
    &--rel {
        position: relative;
    }
}
