.creditsNiice {
    background-color: #fff;
    font-size: 12px;
    color: #828282;
    font-weight: 400;
    letter-spacing: .53px;
    padding: 20px 30px;
    float: left;
    width: 100%;
    &__left {
        float: left;
        font-size: 12px;
        color: #828282;
        font-style: italic;
        font-weight: 400;
        width: 100%;
        margin-right: 0;
        text-align: center;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            text-align: left;
            width: 49%;
            margin-right: 2%;
        }
    }
    &__right {
        color: inherit;
        float: right;
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            text-align: right;
            width: 49%;
            margin-right: 0;
        }
        img {
            position: relative;
        }
        a {
            font-family: Open Sans;
            color: inherit;
            font-size: 12px;
            font-style: italic;
            text-decoration: none;
        }
    }
    .niice {
        font-weight: 700;
    }
}
