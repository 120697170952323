.diersoorten-overzicht,
.diersoorten-entry {
    .animals,
    .specialism {
        @include leader(-2);
        z-index: 10;
        position: relative;
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include leader(-1.5);
            @include span(6);
        }
    }
    .bodyText {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(last 6);
        }
    }
    .stories {
        @include leader(2);
    }
}
