.nav__contact {
    display: none;
    &.navContact--mobile {
        display: inherit;
        left: 0;
        right: inherit;
        position: relative;
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        &.navContact--mobile {
            display: none;
        }
        display: inherit;
    }
    position: absolute;
    right: 0;
    z-index: 999;
    height: 100%;
    top: 0;
    .navContact {
        display: flex;
        align-items: center;
        height: 100%;

        p.small {
            font-size: 12px;
            line-height: 20px;
            color: #5b6277;
            margin: 0;
        }
        h6 {
            @include rhythm(0);
            color: palette(green, base);
            line-height: 1rem;
        }
        h6.small {
            color: palette(blue, base);
        }
        &__left {
            width: 30px;
        }
        &__item {
            display: flex;
            align-items: center;
            padding: 0 16px;

            &--small .navContact__right {
                width: 30px;
            }
        }
    }
}

.navContact--mobile {
    float: left;
}

.openingstijden {
    display: flex;
    align-items: center;
    h6 {
        margin: 0;
        margin-right: 10px;
        line-height: 1.2rem;
    }
}

.navContactToggle {
    background-color: palette(blue);
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    border-radius: 2px;
    padding: 6px 15px;
    width: 107px;

    span {
        margin-right: 8px;
    }

    &--closed {
        display: block;
    }

    &--open {
        display: none;
    }

    &.active {
        .navContactToggle--closed {
            display: none;
        }

        .navContactToggle--open {
            display: block;
        }
    }
}

.navContactWrapper {
    display: none;
    padding: 16px 0;
    min-width: 240px;
    border-top: #dadce5 1px solid;

    &.active {
        display: block;
        margin-top: 56px;
        position: absolute;
        left: -16px;
        top: 0;
        background: #f9faff;
    }

    .navContact__item {

        .navContact__right {
            width: 100%;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: #dadce5 1px solid;
        }

        &:last-child {
            .navContact__right {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
    }
}

.navContactBg {
    pointer-events: none;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 99;
    display: none;
    left: 0;
    top: 0;

    &.active {
        display: block;
    }
}
