.form {
    &__row {
        height: auto;
        overflow: hidden;
        input[type="submit"] {
            @include leader(1);
            cursor: pointer;
        }
    }
    &__field {
        @include leader(1);
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(6);
            &:nth-of-type(2n + 2) {
                @include span(last 6);
            }
        }
        input,
        textarea {
            width: 100%;
        }
        &--full {
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                @include span(12);
            }
        }
    }
}

@media only screen and(max-width: 767px) {
    .freeform-column {
        flex: inherit !important;
    }
    .freeform-row {
        display: block !important;
    }
}
