footer {
    @include rhythm(1,2,0,0);
    .footer__newsletter {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include leader(1);
            @include span(6);
        }
    }
    .mc-field-group {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(6);
            &:nth-of-type(even) {
                @include span(last 6);
            }
        }
    }
    .mc-field-group.input-group {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(12);
        }
        ul {
            margin-top: 5px;
        }
        li {
            &:before {
                content: none;
            }
            padding: 0;
            input,
            label {
                display: inline;
                margin-right: 20px;
            }
        }
    }
    .footer__facebook {
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include leader(1);
            @include trailer(3);
            @include span(last 6);
        }
    }
    .footer--rel {
        position: relative;
        @include trailer(2);
    }
    .footer__first {
        height: auto;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, palette(white, base) 20%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 70%);
            z-index: -1;
            height: 100%;
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                left: 50%;
            }
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            z-index: -2;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-image: url('/assets/images/placeholder.png');
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                left: 50%;
            }
        }
    }
    .footer__links {
        height: auto;
        overflow: hidden;
        @include rhythm(0,1,2,0);
    }
    .footerLinks {
        &__item {
            a {
                color: palette(grey, base);
            }
            h6 {
                margin-top: 0;
                margin-bottom: 20px;
            }
            p {
                margin-top: 0;
            }
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(3);
                &:nth-of-type(4n+4) {
                    @include span(last 3);
                }
            }
        }
    }
    .footerPraktijken {
        border-top: 1px solid rgba(palette(grey, lines), 0.5);
        border-bottom: 1px solid rgba(palette(grey, lines), 0.5);
        height: auto;
        overflow: hidden;
        @include rhythm(0,1,1,0);
        .small {
            @include set-type-size(small);
        }
        &__item {
            img {
                padding-right: 8px;
            }
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(6);
                &:nth-of-type(2n+2) {
                    @include span(last 6);
                }
            }
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                @include span(3);
                &:nth-of-type(2n+2) {
                    @include span(3);
                }
                &:nth-of-type(4n+4) {
                    @include span(last 3);
                }
            }
        }
    }
    .lowerFooter {
        @include rhythm(0,2);
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            &__first {
                @include span(3);
            }
            &__center {
                @include span(6);
            }
            &__last {
                @include span(last 3);
            }
        }
    }
}
