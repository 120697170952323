.nieuws-overzicht {
    .tagsFilter {
        background-color: palette(white);
        border: 1px solid palette(grey, lines);
        @include leader(-2);
        padding-left: 20px lines-to-px(0.5);
        z-index: 10;
        position: relative;
        h4 {
            @include rhythm(0);
            @include trailer(0.5);
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include leader(-1.5);
            @include span(6);
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            padding: lines-to-px(1);
        }
        .button__primary {
            @include leader(1);
        }
    }
    .bodyText {
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(last 6);
        }
    }
}

