.news {
    &--horizontal {
        h3 {
            text-align: center;
        }
        .newsCard__item {
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(6);
                &:nth-of-type(2n+2) {
                    @include span(last 6);
                }
            }
        }
    }
}

.newsCard {
    .flexit__holder {
        width: 100%;
    }
    &__item {
        height: auto;
        border: 1px solid palette(grey, lines);
        display: block;
        transition: border 0.2s ease;
        @include leader(1);
        h6 {
            color: palette(green, base);
            @include rhythm(0);
        }
        .button__tags {
            @include set-type-size(x-small);
            padding: lines-to-px(0) lines-to-px(0.5);
            vertical-align: middle;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                padding: lines-to-px(0.2) lines-to-px(1);
            }
            &:hover {
                background: palette(babyBlue, base);
            }
        }
        &:hover {
            border-color: palette(green);
        }
    }
    &__image {
        @include span(1 inside no-gutter);
        margin-top: -1px;
        margin-bottom: -1px;
        margin-left: -1px;
    }
    &__text {
        @include span(last 3);
    }
    .content {
        display: table-cell;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        &__image {
            @include span(4 inside no-gutter);
        }
        &__text {
            padding-left: 10px;
            @include span(last 8);
        }
    }
}
