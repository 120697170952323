.heroCard {
    height: auto;
    overflow: hidden;
    @include rhythm(0);
    background-color: palette(white);
    position: relative;
    z-index: 20;
    @include susy-breakpoint($breakpoint-medium, $susy-large) {
        padding-left: 0;
        padding-right: 0;
        @include rhythm(-2,2,2,1);
        overflow: hidden;
        border: 1px solid palette(grey, lines);
    }
    &__text {
        @include leader(1);
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include leader(0);
            text-align: center;
        }
        @include trailer(1);
        h3,
        h6 {
            margin: 0;
        }
        &--top {
            @include trailer(0);
            clear: both;
            padding-top: lines-to-px(1);
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                padding-top: lines-to-px(2);
            }
        }
    }
    &__item {
        @include set-type-size(small);
        @include susy-breakpoint($breakpoint-medium, $susy-large) {
            @include span(3 inside no-gutter);
            padding-left: 30px;
            position: relative;
            padding-right: 30px;
            border-right: 1px solid palette(grey, lines);
            height: 490px;
            &:nth-of-type(4n + 4) {
                @include span(last 3 inside no-gutter);
                border-right: 0;
            }
            p {
                color: palette(grey, base);
                @include set-type-size(small);
                line-height: 2rem !important;
                strong {
                    color: palette(blue, base);
                }
                a {
                    font-weight: 600;
                }
            }
        }
        img {
            padding-right: 8px;
        }
        h6 {
            @include rhythm(0);
            @include leader(0.5);
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            &--length3 {
                @include span(4 inside no-gutter);
                &:last-child {
                    @include span(last 4 inside no-gutter);
                    border-right: 0;
                }
                height: inherit;
            }
            &--length2 {
                @include span(6 inside no-gutter);
                &:last-child {
                    @include span(last 6 inside no-gutter);
                    border-right: 0;
                }
                height: inherit;
            }
            &--solo {
                color: palette(grey, light);
            }
        }
        .bottom {
            position: relative;
            padding: 0;
            margin: 10px 0 30px;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                position: absolute;
                bottom: 0;
            }
            h6 {
                color: palette(green, base);
                img {
                    padding-left: 8px;
                }
            }
        }
    }
    &__collapse {
        transition: all ease-in-out 0.5s;
        &.active {
            .heroCard__collapse--title {
                &:after {
                    transform: rotate(90deg);
                }
                &:before {
                    transform: rotate(180deg);
                }
            }
            .heroCard__collapse--content {
                max-height: 350px;
                transition: all ease 1s;
            }
        }
        &--title {
            position: relative;
            max-width: 98%;
            border-top: 1px solid palette(grey, lines);
            img {
                padding: 20px 10px 20px 0;
            }
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                max-height: 60px;
                overflow: hidden;
                border-top: 0;
                &:after,
                &:before {
                    content: none !important;
                }
            }
            &:after {
                position: absolute;
                content: '';
                right: 24px;
                top: 16px;
                background-color: palette(blue);
                height: 16px;
                width: 2px;
                margin-top: 5px;
                transition: all ease-in-out 0.5s;
            }
            &:before {
                position: absolute;
                content: '';
                background-color: palette(blue);
                right: 17px;
                top: 23px;
                height: 2px;
                width: 16px;
                margin-top: 5px;
                transition: all ease-in-out 0.6s;
            }
        }
        &--content {
            max-height: 0;
            overflow: hidden;
            transition: all ease-in-out 0.5s;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                max-height: inherit;
            }
        }
    }
}
