.matrix {
    &--content {
        // custom css
    }
    &__item {
        // Add leaders or trailers
        @include trailer(1);
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include trailer(1.5);
        }
        .freeform-row {
            button {
                @extend .button;
                @extend .button__primary;
            }
        }
    }
    &__left {
        position: relative;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(6);
        }
    }
    &__right {
        position: relative;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(last 6);
        }
    }
    &__title {
        // apply textStyles
    }
    &__text {
        // apply textStyles
        p {
            margin-top: 0;
        }
    }
    &__image {
        // apply image modifications
    }
    &__htmlEmbed {
        // Apply HTML modifications
    }
    &__item--team {
        height: auto;
        overflow: hidden;
        .employee {
            @include susy-breakpoint($breakpoint-medium, $susy-large) {
                @include span(2 of 6);
                &:nth-of-type(3n+3) {
                    @include span(last 2 of 6);
                }
            }
        }
    }
}
